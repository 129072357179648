<template>

    <div class="w-full h-full px-3 py-4">

        <div class="h-full w-full rounded-lg shadow_box flex flex-col p-2 overflow-hidden" :class="'bg-module-'+mode">

            <div class="h-full pb-2 overflow-hidden no-scrollbar w-full flex flex-col">

                <div class=" h-12 w-full flex flex-row justify-start items-center px-2">

                    <span class="text-xl font-semibold" :class="'text-dfont-'+mode">{{$t('createUser')}}</span>

                </div>

                <div class="flex-1 flex flex-col overflow-hidden">

                    <div class="h-8 px-2 py-1">

                        <el-steps :active="active" finish-status="success">
                            <el-step></el-step>
                            <el-step></el-step>
                        </el-steps>

                    </div>

                    <div class="flex-1 flex flex-col overflow-hidden">

                        <div v-if="active==0" class="h-full flex flex-col overflow-hidden">

                            <div class="flex-1 overflow-auto">

                                <!-- nombre -->
                                <div class="h-auto px-2 mt-3 flex flex-col justify-center items-start">

                                    <span class="text-sm mb-1" :class="'text-dfont-'+mode">{{$t('name')}}</span>

                                    <div class="h-8 w-full">
                                        
                                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="{'border border-red':error=='name', 'bg-box-darkmode': mode == 'darkmode', 'bg-box-whitemode': mode == 'whitemode'}">
                                            
                                            <form autocomplete="off" class="w-full">
                                                <input type="text" name="newusermail" :placeholder="$t('name')" class="w-full text-sm" :class="'bg-box-'+mode+' text-dfont-'+mode" v-model="form.user.name">
                                            </form>

                                        </div>

                                    </div>

                                </div>

                                <!-- surname1 -->
                                <div class="h-auto px-2 mt-3 flex flex-col justify-center items-start">

                                    <span class="text-sm mb-1" :class="'text-dfont-'+mode">{{$t('firstSurname')}}</span>

                                    <div class="h-8 w-full">
                                        
                                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                                            
                                            <form autocomplete="off" class="w-full">
                                                <input type="text" name="newusermail" :placeholder="$t('firstSurname')" class="w-full text-sm" :class="'bg-box-'+mode+' text-dfont-'+mode" v-model="form.user.surname1">
                                            </form>

                                        </div>

                                    </div>

                                </div>

                                <!-- surname2 -->
                                <div class="h-auto px-2 mt-3 flex flex-col justify-center items-start">

                                    <span class="text-sm mb-1" :class="'text-dfont-'+mode">{{$t('secondSurname')}}</span>

                                    <div class="h-8 w-full">
                                        
                                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                                            
                                            <form autocomplete="off" class="w-full">
                                                <input type="text" name="newusermail" :placeholder="$t('secondSurname')" class="w-full text-sm" :class="'bg-box-'+mode+' text-dfont-'+mode" v-model="form.user.surname2">
                                            </form>

                                        </div>

                                    </div>

                                </div>

                                <!-- emilio -->
                                <div class="h-auto px-2 mt-3 flex flex-col justify-center items-start">

                                    <span class="text-sm mb-1" :class="'text-dfont-'+mode">Email (login)</span>

                                    <div class="h-8 w-full">
                                        
                                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="{'border border-red':error=='email', 'bg-box-darkmode': mode == 'darkmode', 'bg-box-whitemode': mode == 'whitemode'}">
                                            
                                            <form autocomplete="off" class="w-full">
                                                <input type="email" name="newusermail" placeholder="Email" class="w-full text-sm" :class="'bg-box-'+mode+' text-dfont-'+mode" v-model="form.user.email">
                                            </form>

                                        </div>

                                    </div>

                                </div>

                                <!-- contraseña -->
                                <div class="h-auto px-2 mt-3 flex flex-col justify-center items-start relative">

                                    <span class="text-sm mb-1" :class="'text-dfont-'+mode">{{$t('password')}}  <small><i class="mdi mdi-information-outline text-blue" @click="errorpass = !errorpass"></i></small></span>

                                    <div class="h-8 w-full">
                                        
                                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2 relative" :class="{'border border-red':error=='password', 'bg-box-darkmode': mode == 'darkmode', 'bg-box-whitemode': mode == 'whitemode'}">
                                            
                                            <input :type="typepassword" :placeholder="$t('password')" class="w-full text-sm" :class="'bg-box-'+mode+' text-dfont-'+mode" v-model="form.user.password">

                                            <div class="h-10 w-10 flex flex-row justify-center items-center" @click="typepass()">
                                                <i v-if="typepassword == 'password'" class="mdi mdi-eye" :class="'text-dfont-'+mode"></i>
                                                <i v-if="typepassword == 'text'" class="mdi mdi-eye-off" :class="'text-dfont-'+mode"></i>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <!-- repetir contraseña -->
                                <div class="h-auto px-2 mt-3 flex flex-col justify-center items-start">

                                    <span class="text-sm mb-1" :class="'text-dfont-'+mode">{{$t('repeatPassword')}}</span>

                                    <div class="h-8 w-full">
                                        
                                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="{'border border-red':error=='password', 'bg-box-darkmode': mode == 'darkmode', 'bg-box-whitemode': mode == 'whitemode'}">
                                            
                                            <input :type="typepassword2" :placeholder="$t('repeatPassword')" class="w-full text-sm" :class="'bg-box-'+mode+' text-dfont-'+mode" v-model="password2">

                                            <div class="h-10 w-10 flex flex-row justify-center items-center" @click="typepass2()">
                                                <i v-if="typepassword2 == 'password'" class="mdi mdi-eye" :class="'text-dfont-'+mode"></i>
                                                <i v-if="typepassword2 == 'text'" class="mdi mdi-eye-off" :class="'text-dfont-'+mode"></i>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div class="h-8 flex flex-row justify-center items-center">

                                <div class="h-full w-6/12 rounded-lg bg-red flex flex-row justify-center items-center" @click="nextstep(1)">
                                
                                    <i class="mdi mdi-arrow-right text-white"></i>
                                
                                </div>

                            </div>

                            <div v-if="errorpass" class="absolute h-full w-full top-0 left-0 flex col justify-center items-center" style="backdrop-filter:blur(3px)">

                                <div class="h-auto w-4/5 rounded-lg shadow-card flex flex-col overflow-hidden">
                                
                                    <div class="h-10 w-full bg-red flex flex-row justify-between items-center px-2">

                                        <span class="text-white font-semibold">{{ $t('unsafePassword') }}</span>

                                        <div class="h-10 w-10 flex flex-col justify-center items-center" @click="errorpass = !errorpass">
                                            <i class="mdi mdi-close text-white"></i>
                                        </div>

                                    </div>

                                    <div class="flex-1 flex flex-col justify-center items-start p-4" :class="'bg-module-'+mode">

                                        <span class="font-semibold mb-2" :class="'text-dfont-'+mode"><span class="font-bold">·</span> {{$t('passwordMustBe')}}</span>
                                        <span :class="'text-dfont-'+mode"><span class="font-bold">·</span> {{$t('betweenCharacters')}}</span>
                                        <span :class="'text-dfont-'+mode"><span class="font-bold">·</span> {{$t('leastOneDigit')}}</span>
                                        <span :class="'text-dfont-'+mode"><span class="font-bold">·</span> {{$t('leastLowerAndUpperCase')}}</span>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div v-if="active==1" class="h-full flex flex-col overflow-hidden">
                            
                            <div class="flex-1 overflow-auto">

                                <!-- videoWall -->
                                <div class="h-8 px-2 mt-6 flex flex-row justify-center items-center">

                                    <span class="text-sm font-semibold mr-auto" :class="'text-dfont-'+mode">Usuario Videowall</span>
                                    <el-switch v-model="form.user.videoWall" active-color="#409EFF" inactive-color="#409EFF"></el-switch>
                                    <span class="text-sm font-semibold ml-auto" :class="'text-dfont-'+mode">Usuario Aplicación</span>

                                </div>

                                <!-- rol -->
                                <div class="h-auto px-2 mt-6 flex flex-col justify-center items-start">

                                    <span class="text-sm mb-1" :class="'text-dfont-'+mode">Rol</span>

                                    <All model="UserType" :immediate="true" v-slot="{data:usertype, loading:loadingusertype}">
                                        <div v-if="!loadingusertype" class="h-8 w-full">
                                            
                                            <div class="h-full w-full rounded-lg flex flex-row justify-between items-center" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                                
                                                <!-- <el-select v-model="form.user.IdType" filterable :placeholder="$t('selectRol')" class="w-full text-dfont">
                                                    
                                                    <el-option
                                                    v-for="item in usertype"
                                                    :key="item.Id"
                                                    :label="item.Name"
                                                    :value="item.Id"
                                                    class="w-full">
                                                    </el-option>

                                                </el-select> -->

                                                <select name="-" v-model="form.user.IdType" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                                    <option v-for="(el,index) in usertype" :key="index" :value="el.Id" :class="'text-dfont-'+mode">{{el.Name}}</option>
                                                </select>

                                            </div>

                                        </div>
                                    </All>

                                </div>

                                <!-- admin -->
                                <div v-if="form.user.IdType == '8d27cef0394b11ea966b005056aeaa71'" class="h-8 px-2 mt-6 flex flex-row justify-between items-center">

                                    <span class="text-sm mb-1" :class="'text-dfont-'+mode">{{$t('administrator')}}</span>

                                    <el-switch v-model="form.user.admin" :active-value="1" :inactive-value="0"></el-switch>

                                </div>

                                <!-- <div v-if="form.user.IdType != '8d27cef0394b11ea966b005056aeaa71'" class="h-8 flex flex-row justify-center items-center mt-6" @click="gotorelations()">

                                    <span class="text-purple font-semibold">{{$t('addRelations')}}</span>

                                </div> -->

                            </div>
                            
                            <div class="h-8 flex flex-row justify-center items-center">

                                <div class="h-full w-5/12 mx-auto rounded-lg bg-red flex flex-row justify-center items-center" @click="prevstep()">
                                
                                    <i class="mdi mdi-arrow-left text-white"></i>
                                
                                </div>
                                
                                <div class="h-full w-5/12 mx-auto relative">

                                    <div v-if="!form.user.IdType" class="absolute top-0 left-0 h-full w-full bg-gray bg-opacity-50"></div>

                                    <Request
                                    model="RegisterUser"
                                    action="create"
                                    :form="form"
                                    @success="onSuccess"
                                    @error="onError"
                                    v-slot="{ request, loading }" class="h-full w-full">

                                        <div v-if="!loading" class="h-full w-full bg-red rounded-lg  flex flex-row justify-center items-center"  @click="request">
                                            <span class="text-white font-semibold">{{$t('createUser')}}</span>
                                        </div>

                                    </Request>

                                </div>

                            </div>
                        
                        </div>

                    </div>                    

                </div>

            </div>

        </div>

    </div>

</template>

<script>
import basiclinechart from '../../components/basiclinechart.vue';
import { All, Request } from '@/api/components';
import { state, actions } from '@/store';

export default {
    components:{
        basiclinechart,
        All,
        Request
    },
    data(){
        return{
            form: {
                user:{
                    name: '',
                    email: '',
                    surname1: '',
                    surname2: '',
                    password: '',
                    IdType: null,
                    videoWall: false,
                    admin: 0
                }
            },
            rols:[
                'Propiedad',
                'Gerente',
                'Comercial',
                'Representante'
            ],
            password2:'',
            active: 0,
            error: false,
            errorpass:false,
            typepassword: 'password',
            typepassword2: 'password'
        }
    },
    methods:{
        async onSuccess(response){

            this.$message({
                message: 'Usuario creado',
                type: 'success'
            });
            actions.setUserToEdit(response.id)

            if(response.IdType == '8d27cef0394b11ea966b005056aeaa71'){
                
                this.$router.push( {name:'users'} )
            
            } else {

                this.$router.push( { name: 'relations' } )

            }

        },
        onError(error){
            
            if(error.response.data.errors.source.pointer.split('/')[3] == 'email' && error.response.data.errors.title == 'Duplicated'){

                this.$message.error('El email ya está registrado')

            } else {

                this.$message.error('Error')

            }



        },
        nextstep(number){

            if(number == 1){
                
                let error = false

                if(this.validarEmail(this.form.user.email) && this.validatePass(this.form.password)){

                    

                    if(this.form.user.name != ''){

                        if(this.form.user.password == this.password2 && this.password2 != ''){

                            if(this.form.user.email != ''){

                                error=false

                            } else {
                                error='email'
                            }

                        } else {
                            error='password'
                        }

                    } else {
                        error='name'
                    }
                    
                
                    if(error == false){

                        this.active++

                    } else{
                        
                        this.error = error

                    }
                
                } else{

                    // error = 'email'
                
                }

            }

            if(number==2){  

            }

            

        },
        prevstep(){

            this.active--

        },
        gotorelations(){
            this.$router.push( { name:'relations' } )
        },
        validarEmail(valor){
            
            let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
            
            if (emailRegex.test(valor)) {
                return true
            } else {
                this.$message.error('Email no válido');
                return false
            }

        },
        validatePass(){

            let passRegex = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

            if(passRegex.test(this.form.user.password)){

                return true

            }else{

                this.errorpass = !this.errorpass
                return false
            }


        },
        typepass(){

            if(this.typepassword == 'password'){
                this.typepassword = 'text'
            } else {
                this.typepassword = 'password'
            }

        },
        typepass2(){

            if(this.typepassword2 == 'password'){
                this.typepassword2 = 'text'
            } else {
                this.typepassword2 = 'password'
            }

        }
    },
    computed:{
        user(){
            return state.user
        },
        querycompanies(){
            return{
                period: state.period,
                Dimension: state.dimensionGroup
            }
        },
        mode(){
            return state.mode
        }

    },
    watch:{
        
    }
}
</script>